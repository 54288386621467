import AppConstants from "#/src/utils/app-constants";
import {Turnstile} from "@marsidev/react-turnstile";
import styled from "@emotion/styled";

const Container = styled.div`
  margin: auto;
`;

interface Props {
  onSuccess: (token: string) => void
}

export function Captcha({onSuccess}: Props) {
  const options: any = {size: "invisible", appearance: "interaction-only"};
  return <Container><Turnstile siteKey={AppConstants.TURNSTILE_SITE_KEY} options={options} onSuccess={onSuccess}/></Container>
}